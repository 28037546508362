import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

import $ from '../../styles/global';
import Utils from '../../utils';
import CheckIcon from '../../assets/icons/check.svg';
import ClockIcon from '../../assets/icons/clock.svg';
import ToolIcon from '../../assets/icons/tool.svg';
import PaintIcon from '../../assets/icons/paint.svg';

const Section = styled.section`
  padding-bottom: 100px;
`;

const Container = styled.div``;

const Title = styled.h2`
  text-align: center;
  font-size: 30px;
  color: ${$.color.base};
  margin-bottom: ${$.layout().margin4}px;
  line-height: 1.5em;
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 50px;
  padding: 0 ${$.layout().padding1}px;

  ${$.device.mobile} {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 80px;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > svg {
    width: 40px;
    height: 40px;
  }

  & > *:not(:last-child) {
    margin-bottom: ${$.layout().margin3}px;
  }

  ${$.device.mobile} {
    text-align: center;
    align-items: center;
  }
`;

const Subtitle = styled.h3`
  ${({ color }) => `
  color: ${color};
  font-family: Lato Regular;
`}
`;

const Desc = styled.p`
  color: ${$.color.base};
`;

const items = Utils.addKeys([
  {
    sub: 'World-class quality control',
    desc: 'Poles are manufactured under Japanese Industrial Standard (JIS A5309) requiring strict adherence to rigid quality control, resulting in superior prestressed spun concrete poles.',
    color: '#04a777',
    icon: <CheckIcon />,
  },
  {
    sub: 'Long lasting',
    desc: 'Our concrete products last longer compared to wooden and steel poles which are subject to decay rust, termite attack and damage by fire.',
    icon: <ClockIcon />,
    color: '#e9c46a',
  },
  {
    sub: 'Low maintenance',
    desc: 'Our concrete poles do not require painting nor do they need to be maintained periodically. Install our poles with ease of mind.',
    color: '#f4a261',
    icon: <ToolIcon />,
  },
  {
    sub: 'Attractive finish',
    desc: "A modern look in keeping up with today's aesthetics requirement. Smooth texture with a light grey base, our poles do not bend with age.",
    color: '#e63946',
    icon: <PaintIcon />,
  },
]);

const Reasons = () => (
  <Section>
    <Container>
      <Title>Why choose our products?</Title>
      <Cards>
        {items.map(({ sub, desc, key, color, icon }) => (
          <Fade bottom distance="100px" key={key}>
            <Card>
              {icon}
              <Subtitle color={color}>{sub}</Subtitle>
              <Desc>{desc}</Desc>
            </Card>
          </Fade>
        ))}
      </Cards>
    </Container>
  </Section>
);

export default Reasons;
