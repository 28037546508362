import React from 'react';

import Seo from '../components/seo';
import Banner from '../components/Products/Banner';
import Reasons from '../components/Products/Reasons';
import ConcretePole from '../components/Products/ConcretePole';
import DecoPole from '../components/Products/DecoPole';
import MalaysiaRailwayPole from '../components/Products/MalaysiaRailwayPole';

const ProductsPage = () => (
  <>
    <Seo title="Products | Zenn HRB" />
    <Banner />
    <Reasons />
    <ConcretePole />
    <DecoPole />
    <MalaysiaRailwayPole />
  </>
);

export default ProductsPage;
