import Utils from '../../../utils';

const images = Utils.addKeys([
  {
    src: Utils.importOneImage('products/deco-pole/pole-1-img.png'),
    thumbnail: Utils.importOneImage('products/deco-pole/pole-1-img.png'),
  },
  {
    src: Utils.importOneImage('products/deco-pole/pole-2-img.png'),
    thumbnail: Utils.importOneImage('products/deco-pole/pole-2-img.png'),
  },
  {
    src: Utils.importOneImage('products/deco-pole/pole-3-img.png'),
    thumbnail: Utils.importOneImage('products/deco-pole/pole-3-img.png'),
  },
]);

export default images;
