import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import SimpleReactLightbox from 'simple-react-lightbox';
import Fade from 'react-reveal/Fade';

import StandardPoleProperties from './StandardPoleProperties';
import DownloadBrochureButton from '../PoleSection/DownloadBrochureButton';
import Utils from '../../../utils';
import { ImageContainer, Content } from '../PoleSection';
import CarouselLightbox from '../../CarouselLightbox';
import $ from '../../../styles/global';
import images from './images';

const Section = styled.section`
  padding-bottom: 100px;
`;

const Container = styled.div`
  & > button {
    margin: 0 auto;
  }

  & > div,
  > h2 {
    padding: 0 ${$.layout().padding1}px;
  }
`;

const items = Utils.addKeys([
  {
    text: 'First designed and constructed by French engineer Freyssinet in the middle 1930s, prestressed concrete poles are used today worldwide for flag poles, sign poles, telephone poles, telegraph poles, and poles that support street lights, electric power lines, antenna masts, and overhead power lines for electric trains.',
  },
  {
    text: 'Compared with normally reinforced concrete poles, prestressed concrete poles offer several advantages: prestressed poles are lighter and stronger, and they require less reinforcing steel. The concrete is generally in compression, so cracking is unlikely.',
  },
  {
    text: 'Our concrete poles have a proven track record with multiple satisfied customers from various sectors - proving the befitting factor and versatility of our products.',
  },
]);

const ConcretePole = () => (
  <Section id="prestressed-spun-concrete-poles">
    <Container>
      <Fade bottom distance="100px">
        <Content
          title="Prestressed Spun Concrete Poles"
          paragraphs={items}
          link="/prestressed-spun-concrete-poles.pdf"
        />
        <ImageContainer>
          <StaticImage
            src="../../../assets/images/products/concrete-pole/slide-3.png"
            alt=""
          />
          <StaticImage
            src="../../../assets/images/products/concrete-pole/slide-2.jpg"
            alt=""
          />
        </ImageContainer>
      </Fade>
      {/* You MUST create individual context in this manner if you want to use the carousel + lightbox */}
      <Fade bottom distance="100px">
        <SimpleReactLightbox>
          <CarouselLightbox images={images} />
        </SimpleReactLightbox>
        <StandardPoleProperties />
        <DownloadBrochureButton link="/prestressed-spun-concrete-poles.pdf" />
      </Fade>
    </Container>
  </Section>
);

export default ConcretePole;
