import React from 'react';
import styled from 'styled-components';

import $ from '../../../styles/global';

const Container = styled.div`
  margin-bottom: ${$.layout().margin4}px;
`;

const InnerContainer = styled.div`
  overflow: auto;
`;

const Title = styled.div`
  color: ${$.color.base};
  font-size: 30px;
  font-family: Lato Regular;
  margin-bottom: ${$.layout().margin4}px;
`;

const Table = styled.table`
  border-collapse: collapse;
  border: 1px solid ${$.color.base};
  text-align: center;
  font-size: 17px;
  width: 100%;

  sub,
  sup {
    font-size: 12px;
  }

  & thead {
    font-family: Lato Bold;
  }

  & tr {
    border: 1px solid ${$.color.base};
  }
  td {
    min-width: 50px;
    padding: 5px;
    &:not(:last-child) {
      border-right: 1px solid ${$.color.base};
    }
  }
`;

const StandardPoleProperties = () => (
  <Container>
    <Title>Standard Pole Properties</Title>
    <InnerContainer>
      <Table>
        <thead>
          <tr>
            <td rowSpan="2">Pole Marking</td>
            <td colSpan="2">
              Length
              <br />
              (L)
            </td>
            <td colSpan="2">
              Top Diameter
              <br />
              (d<sub>0</sub>)
            </td>
            <td colSpan="2">
              Bottom Diameter
              <br />
              (D<sub>0</sub>)
            </td>
            <td colSpan="2">
              Height of Bearing Point
              <br />
              (I<sub>1</sub>)
            </td>
            <td colSpan="2">
              Height of Bearing Point
              <br />
              (I<sub>2</sub>)
            </td>
            <td colSpan="2">
              Design Load
              <br />
              (P)
            </td>
            <td colSpan="2">
              Design Bending Moment
              <br />
              (P X I<sub>2</sub>)
            </td>
            <td colSpan="2">Nominal Weight</td>
          </tr>
          <tr>
            <td>m</td>
            <td>ft</td>
            <td>cm</td>
            <td>in</td>
            <td>cm</td>
            <td>in</td>
            <td>m</td>
            <td>Ft</td>
            <td>m</td>
            <td>ft</td>
            <td>kg</td>
            <td>lbs</td>
            <td>kg m</td>
            <td>ft.lbs</td>
            <td>kg</td>
            <td>lbs</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>9-19-4085</td>
            <td>9</td>
            <td>29.5</td>
            <td>19</td>
            <td>
              7<sup>1</sup>&frasl;<sub>2</sub>
            </td>
            <td>31</td>
            <td>
              12<sup>1</sup>&frasl;<sub>4</sub>
            </td>
            <td>1.1</td>
            <td>3.6</td>
            <td>7.65</td>
            <td>25</td>
            <td>534</td>
            <td>1177</td>
            <td>4085</td>
            <td>29550</td>
            <td>750</td>
            <td>1654</td>
          </tr>
          <tr>
            <td>9-22-7650</td>
            <td>9</td>
            <td>29.5</td>
            <td>22</td>
            <td>
              8<sup>11</sup>&frasl;<sub>16</sub>
            </td>
            <td>34</td>
            <td>
              13<sup>1</sup>&frasl;<sub>4</sub>
            </td>
            <td>1.1</td>
            <td>3.6</td>
            <td>7.65</td>
            <td>25</td>
            <td>1000</td>
            <td>2205</td>
            <td>7650</td>
            <td>55330</td>
            <td>1120</td>
            <td>2470</td>
          </tr>
          <tr>
            <td>9.5-22-5102</td>
            <td>9.5</td>
            <td>31.1</td>
            <td>22</td>
            <td>
              8<sup>11</sup>&frasl;<sub>16</sub>
            </td>
            <td>34.7</td>
            <td>
              13<sup>1</sup>&frasl;<sub>2</sub>
            </td>
            <td>1.1</td>
            <td>3.6</td>
            <td>8.15</td>
            <td>26.7</td>
            <td>626</td>
            <td>1380</td>
            <td>5102</td>
            <td>36900</td>
            <td>910</td>
            <td>2007</td>
          </tr>
          <tr>
            <td>11.5-30-15308</td>
            <td>11.5</td>
            <td>37.6</td>
            <td>30</td>
            <td>
              11<sup>3</sup>&frasl;<sub>4</sub>
            </td>
            <td>45.3</td>
            <td>
              17<sup>3</sup>&frasl;<sub>4</sub>
            </td>
            <td>1.5</td>
            <td>4.9</td>
            <td>9.75</td>
            <td>32</td>
            <td>1570</td>
            <td>3462</td>
            <td>15308</td>
            <td>110700</td>
            <td>2060</td>
            <td>4542</td>
          </tr>
          <tr>
            <td>12.5-30-16329</td>
            <td>12.5</td>
            <td>40.9</td>
            <td>30</td>
            <td>
              11<sup>3</sup>&frasl;<sub>4</sub>
            </td>
            <td>46.7</td>
            <td>
              18<sup>1</sup>&frasl;<sub>4</sub>
            </td>
            <td>1.5</td>
            <td>4.9</td>
            <td>10.75</td>
            <td>35.3</td>
            <td>1519</td>
            <td>3349</td>
            <td>16329</td>
            <td>118000</td>
            <td>2290</td>
            <td>5049</td>
          </tr>
          <tr>
            <td>13.5-30-18365</td>
            <td>13.5</td>
            <td>44.2</td>
            <td>30</td>
            <td>
              11<sup>3</sup>&frasl;<sub>4</sub>
            </td>
            <td>48</td>
            <td>
              18<sup>3</sup>&frasl;<sub>4</sub>
            </td>
            <td>1.5</td>
            <td>4.9</td>
            <td>11.75</td>
            <td>38.5</td>
            <td>1563</td>
            <td>3346</td>
            <td>18365</td>
            <td>132900</td>
            <td>2540</td>
            <td>5601</td>
          </tr>
        </tbody>
      </Table>
    </InnerContainer>
  </Container>
);

export default StandardPoleProperties;
