import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';
import DownloadIcon from '../../../assets/icons/download.svg';

const Container = styled.a`
  text-decoration: none;
  border: 2px solid transparent;
  background-color: ${$.color.red};
  min-width: 150px;
  border-radius: 20px;
  color: ${$.color.white};
  font-family: Lato Bold;
  font-size: 16px;
  padding: 8px 16px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.3s ease;

  & > svg {
    margin-left: ${$.layout().margin1}px;
    fill: ${$.color.white};
    width: 24px;
    height: 24px;
    transition: all 0.3s ease;
  }

  &:hover {
    cursor: pointer;
    border: 2px solid ${$.color.red};
    color: ${$.color.red};
    background-color: ${$.color.white};
    & > svg {
      fill: ${$.color.red};
    }
  }
`;

const DownloadBrochureButton = ({ link }) => (
  <Container href={link} download={link.replace('/', '').replace('.pdf', '')}>
    Download brochure
    <DownloadIcon />
  </Container>
);

DownloadBrochureButton.propTypes = {
  link: PropTypes.string.isRequired,
};

export default DownloadBrochureButton;
