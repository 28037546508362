import React from 'react';
import styled from 'styled-components';

import $ from '../../../styles/global';

const Container = styled.div`
  margin-bottom: ${$.layout().margin4}px;
`;

const InnerContainer = styled.div`
  overflow: auto;
`;

const Title = styled.div`
  color: ${$.color.base};
  font-size: 30px;
  font-family: Lato Regular;
  margin-bottom: ${$.layout().margin4}px;
`;

const Table = styled.table`
  border-collapse: collapse;
  border: 1px solid ${$.color.base};
  text-align: center;
  font-size: 17px;
  width: 100%;

  sub,
  sup {
    font-size: 12px;
  }

  & thead {
    font-family: Lato Bold;
  }

  & tr {
    border: 1px solid ${$.color.base};
  }
  td {
    min-width: 50px;
    padding: 5px;
    &:not(:last-child) {
      border-right: 1px solid ${$.color.base};
    }
  }
`;

const StandardPoleProperties = () => (
  <Container>
    <Title>Standard Pole Properties</Title>
    <InnerContainer>
      <Table>
        <thead>
          <tr>
            <td rowSpan="2">Pole Marking</td>
            <td colSpan="2">
              Length
              <br />
              (L)
            </td>
            <td colSpan="2">
              Top Diameter
              <br />
              (d<sub>0</sub>)
            </td>
            <td colSpan="2">
              Bottom Diameter
              <br />
              (D<sub>0</sub>)
            </td>
            <td colSpan="2">
              Design Load Point
              <br />
              (P)
            </td>
            <td colSpan="2">Nominal Weight</td>
            <td colSpan="2">
              Design Bending Moment
              <br />
              (P X I<sub>2</sub>)
            </td>
          </tr>
          <tr>
            <td>m</td>
            <td>ft</td>
            <td>cm</td>
            <td>in</td>
            <td>cm</td>
            <td>in</td>
            <td>kg</td>
            <td>lbs</td>
            <td>kg</td>
            <td>lbs</td>
            <td>kg.m</td>
            <td>ft.lbs</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>3.5-11-120</td>
            <td>3.5</td>
            <td>11.5</td>
            <td>11</td>
            <td>
              4<sup>5</sup>&frasl;<sub>16</sub>
            </td>
            <td>26</td>
            <td>
              10<sup>4</sup>&frasl;<sub>16</sub>
            </td>
            <td>120</td>
            <td>265</td>
            <td>180</td>
            <td>397</td>
            <td>390</td>
            <td>2825</td>
          </tr>
          <tr>
            <td>10.0-12-150</td>
            <td>10.0</td>
            <td>32.8</td>
            <td>12</td>
            <td>
              4<sup>11</sup>&frasl;<sub>16</sub>
            </td>
            <td>30.0</td>
            <td>
              11<sup>13</sup>&frasl;<sub>16</sub>
            </td>
            <td>150</td>
            <td>331</td>
            <td>700</td>
            <td>1544</td>
            <td>1463</td>
            <td>10581</td>
          </tr>
        </tbody>
      </Table>
    </InnerContainer>
  </Container>
);

export default StandardPoleProperties;
