import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';
import RightChevronIcon from '../../../assets/icons/right-chevron.svg';
import DownloadBrochureButton from './DownloadBrochureButton';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${$.layout().margin4}px;

  ${$.device.desktop} {
    & > *:first-child {
      margin-right: ${$.layout().margin4}px;
    }
  }

  ${$.device.tablet} {
    & > *:first-child {
      margin-right: ${$.layout().margin4}px;
    }
  }

  ${$.device.mobile} {
    flex-direction: column;
    & > *:first-child {
      margin-bottom: ${$.layout().margin3}px;
    }
  }
`;

const ContactUsLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: ${$.color.red};
  font-family: Lato Bold;
  font-size: 17px;

  & > svg {
    margin-left: ${$.layout().margin2}px;
    fill: ${$.color.red};
    width: 24px;
    height: 24px;
  }
`;

const Buttons = ({ link }) => (
  <Container>
    <DownloadBrochureButton link={link} />
    <ContactUsLink to="/contact-us/">
      Contact Us
      <RightChevronIcon />
    </ContactUsLink>
  </Container>
);

Buttons.propTypes = {
  link: PropTypes.string.isRequired,
};

export default Buttons;
