import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';

/**
 * If you see lots of !important, thats because GatsbyImage
 * is not respecting parent width.
 */
const Container = styled.div`
  ${({ number }) => `
  position: relative;
  margin-bottom: 80px;

  & > * {
    width: 100% !important;
    height: 100% !important;
    opacity: 0;
    top: 0;
    position: absolute;
    transition: all 0.9s ease-in;
    &:nth-child(${number}) {
      opacity: 1;
    }
  }

  ${$.device.desktop} {
    width: 100%;
    height: 500px;
  }

  ${$.device.tablet} {
    height: 400px;
    width: calc(100% + ${$.layout().padding4 * 2}px);
    left: -${$.layout().padding4}px;
    padding: 0 !important;

    & > * {
      width: 100% !important;
    }
  }

  ${$.device.mobile} {
    height: 400px;
    width: calc(100% + ${$.layout().padding4 * 2}px);
    left: -${$.layout().padding4}px;
    padding: 0 !important;

    & > * {
      width: 100% !important;
    }
  }
`}
`;

const ImageContainer = ({ children }) => {
  const [fade, setFade] = useState(1);

  useEffect(() => {
    const interval =
      children.length > 1
        ? setInterval(() => {
            setFade((number) => {
              if (number >= children.length) {
                return 1;
              }

              return number + 1;
            });
          }, 5000)
        : undefined;

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  return <Container number={fade}>{children}</Container>;
};

ImageContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default ImageContainer;
