import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import SimpleReactLightbox from 'simple-react-lightbox';
import Fade from 'react-reveal/Fade';

import StandardPoleProperties from './StandardPoleProperties';
import DownloadBrochureButton from '../PoleSection/DownloadBrochureButton';
import Utils from '../../../utils';
import { Content } from '../PoleSection';
import CarouselLightbox from '../../CarouselLightbox';
import $ from '../../../styles/global';
import images from './images';

const Section = styled.section`
  padding-bottom: 100px;
`;

const Container = styled.div`
  & > button {
    margin: 0 auto;
  }

  & > div,
  > h2 {
    padding: 0 ${$.layout().padding1}px;
  }
`;

const ImageContainer = styled.div`
  margin-bottom: 80px;

  ${$.device.tablet} {
    width: calc(100% + ${$.layout().margin3 * 2}px);
    position: relative;
    left: -${$.layout().margin3}px;
  }

  ${$.device.mobile} {
    width: calc(100% + ${$.layout().margin3 * 2}px);
    position: relative;
    left: -${$.layout().margin3}px;
  }
`;

const items = Utils.addKeys([
  {
    text: "Zenn HRB's Malaysia Railway poles are built for the railway double-track electrification programme. We have designed the poles so that they are easy to install and can be easily modified. The poles are made of high quality steel and can be installed in any rail track in any direction. The poles are also not heavy weight, making them suitable for use in all kinds of railway tracks.",
  },
  {
    text: 'Our specially constructed poles are approved under the stringent standards set by Malaysia Railway and Balfour Beatty Ansaldo Systems JV Sdn Bhd and also act as structured masts for the stringing of catenary conductors.',
  },
  {
    text: 'Our Malaysia railway poles are time-tested and has proven to be best-in-class in reliability - making it the best choice for any railway tracks.',
  },
]);

const MalaysiaRailwayPole = () => (
  <Section id="malaysia-railway-pole">
    <Container>
      <Fade bottom distance="100px">
        <Content
          title="Malaysia Railway Pole"
          paragraphs={items}
          link="/decorative-poles.pdf"
        />
        <ImageContainer>
          <StaticImage
            src="../../../assets/images/products/malaysia-railway-pole/malaysia-railway-poles-1.jpg"
            alt=""
          />
        </ImageContainer>
      </Fade>
      <Fade bottom distance="100px">
        {/* You MUST create individual context in this manner if you want to use the carousel + lightbox */}
        <SimpleReactLightbox>
          <CarouselLightbox images={images} slidesToShow={1} />
        </SimpleReactLightbox>
        <StandardPoleProperties />
        <DownloadBrochureButton link="/malaysia-railway-poles.pdf" />
      </Fade>
    </Container>
  </Section>
);

export default MalaysiaRailwayPole;
