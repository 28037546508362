import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';
import Buttons from './Buttons';

const Tag = styled.div`
  font-size: 15px;
  color: ${$.color.red};
  font-family: Lato Bold;
  margin-bottom: ${$.layout().margin3}px;
`;

const Title = styled.h2`
  font-size: 35px;
  font-family: Lato Regular;
  color: ${$.color.base};
  margin-bottom: ${$.layout().margin3}px;
  line-height: 1.2em;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: ${$.layout().margin4}px;

  ${$.device.mobile} {
    flex-direction: column;
    justify-content: initial;
  }
`;

const Column = styled.div`
  width: 45%;
  & p {
    &:not(:last-child) {
      margin-bottom: ${$.layout().margin2}px;
    }
  }

  ${$.device.mobile} {
    width: 100%;
    margin-bottom: ${$.layout().margin2}px;
  }
`;

const PoleSection = ({ title, paragraphs, link }) => {
  const split = Math.ceil(paragraphs.length / 2);

  return (
    <>
      <Tag>Our Products</Tag>
      <Title>{title}</Title>
      <Content>
        <Column>
          {paragraphs.slice(0, split).map(({ key, text }) => (
            <p key={key}>{text}</p>
          ))}
        </Column>
        <Column>
          {paragraphs.slice(split, paragraphs.length).map(({ key, text }) => (
            <p key={key}>{text}</p>
          ))}
        </Column>
      </Content>
      <Buttons link={link} />
    </>
  );
};

PoleSection.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  paragraphs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PoleSection;
