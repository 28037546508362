import React from 'react';
import styled from 'styled-components';

import $ from '../../../styles/global';

const Container = styled.div`
  margin-bottom: ${$.layout().margin4}px;
`;

const Title = styled.div`
  color: ${$.color.base};
  font-size: 30px;
  font-family: Lato Regular;
  margin-bottom: ${$.layout().margin4}px;
`;

const InnerContainer = styled.div`
  overflow: auto;
`;

const Table = styled.table`
  border-collapse: collapse;
  border: 1px solid ${$.color.base};
  text-align: center;
  font-size: 17px;
  width: 100%;

  sub,
  sup {
    font-size: 12px;
  }

  & thead {
    font-family: Lato Bold;
  }

  & tr {
    border: 1px solid ${$.color.base};
  }
  td {
    min-width: 50px;
    padding: 5px;
    &:not(:last-child) {
      border-right: 1px solid ${$.color.base};
    }
  }
`;

const StandardPoleProperties = () => (
  <Container>
    <Title>Standard Pole Properties</Title>
    <InnerContainer>
      <Table>
        <thead>
          <tr>
            <td rowSpan="2">Pole Marking</td>
            <td colSpan="2">
              Length
              <br />
              (L)
            </td>
            <td colSpan="2">
              Top Diameter
              <br />
              (d<sub>0</sub>)
            </td>
            <td colSpan="2">
              Bottom Diameter
              <br />
              (D<sub>0</sub>)
            </td>
            <td colSpan="2">
              Height of Bearing Point
              <br />
              (I<sub>1</sub>)
            </td>
            <td colSpan="2">
              Design Load Point
              <br />
              (P)
            </td>
            <td colSpan="2">
              Design Bending Moment
              <br />
              (P X I<sub>2</sub>)
            </td>
            <td colSpan="2">Nominal Weight</td>
          </tr>
          <tr>
            <td>m</td>
            <td>ft</td>
            <td>cm</td>
            <td>in</td>
            <td>cm</td>
            <td>in</td>
            <td>m</td>
            <td>ft</td>
            <td>kg</td>
            <td>lbs</td>
            <td>kg.m</td>
            <td>ft.lbs</td>
            <td>kg</td>
            <td>ft.lbs</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>6.7M-0.8KN</td>
            <td>6.7</td>
            <td>22.0</td>
            <td>10</td>
            <td>
              3<sup>15</sup>&frasl;<sub>16</sub>
            </td>
            <td>16.7</td>
            <td>
              6<sup>3</sup>&frasl;<sub>4</sub>
            </td>
            <td>1.15</td>
            <td>3.8</td>
            <td>80</td>
            <td>147</td>
            <td>424</td>
            <td>3180</td>
            <td>180</td>
            <td>400</td>
          </tr>
          <tr>
            <td>7.5M-1.1KN</td>
            <td>7.5</td>
            <td>24.7</td>
            <td>10</td>
            <td>4</td>
            <td>17.5</td>
            <td>
              6<sup>3</sup>&frasl;<sub>4</sub>
            </td>
            <td>1.30</td>
            <td>4.2</td>
            <td>112</td>
            <td>247</td>
            <td>661</td>
            <td>4780</td>
            <td>210</td>
            <td>462</td>
          </tr>
          <tr>
            <td>7.5M-2.0KN</td>
            <td>7.5</td>
            <td>24.7</td>
            <td>14</td>
            <td>
              5<sup>1</sup>&frasl;<sub>2</sub>
            </td>
            <td>24.0</td>
            <td>
              9<sup>1</sup>&frasl;<sub>2</sub>
            </td>
            <td>1.30</td>
            <td>4.2</td>
            <td>204</td>
            <td>450</td>
            <td>1140</td>
            <td>8246</td>
            <td>320</td>
            <td>704</td>
          </tr>
          <tr>
            <td>9.0M-2.0KN</td>
            <td>9.0</td>
            <td>29.7</td>
            <td>14</td>
            <td>
              5<sup>1</sup>&frasl;<sub>2</sub>
            </td>
            <td>26.0</td>
            <td>
              10<sup>1</sup>&frasl;<sub>4</sub>
            </td>
            <td>1.50</td>
            <td>4.9</td>
            <td>204</td>
            <td>450</td>
            <td>1406</td>
            <td>10170</td>
            <td>410</td>
            <td>902</td>
          </tr>
          <tr>
            <td>10.0M-5.0KN</td>
            <td>10.0</td>
            <td>32.8</td>
            <td>19</td>
            <td>
              7<sup>1</sup>&frasl;<sub>2</sub>
            </td>
            <td>32.3</td>
            <td>
              12<sup>3</sup>&frasl;<sub>4</sub>
            </td>
            <td>1.70</td>
            <td>5.5</td>
            <td>500</td>
            <td>1102</td>
            <td>4025</td>
            <td>29112</td>
            <td>780</td>
            <td>1720</td>
          </tr>
        </tbody>
      </Table>
    </InnerContainer>
  </Container>
);

export default StandardPoleProperties;
