import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import SimpleReactLightbox from 'simple-react-lightbox';
import Fade from 'react-reveal/Fade';

import StandardPoleProperties from './StandardPoleProperties';
import DownloadBrochureButton from '../PoleSection/DownloadBrochureButton';
import Utils from '../../../utils';
import { Content } from '../PoleSection';
import CarouselLightbox from '../../CarouselLightbox';
import $ from '../../../styles/global';
import images from './images';

const Section = styled.section`
  padding-bottom: 100px;
`;

const Container = styled.div`
  & > button {
    margin: 0 auto;
  }

  & > div,
  > h2 {
    padding: 0 ${$.layout().padding1}px;
  }
`;

const ImageContainer = styled.div`
  margin-bottom: 80px;

  ${$.device.tablet} {
    width: calc(100% + ${$.layout().margin3 * 2}px);
    position: relative;
    left: -${$.layout().margin3}px;
  }

  ${$.device.mobile} {
    width: calc(100% + ${$.layout().margin3 * 2}px);
    position: relative;
    left: -${$.layout().margin3}px;
  }
`;

const items = Utils.addKeys([
  {
    text: "Decorative lighting is vital to urban development - creating a distinct sense of community and upscale aesthetics. Decorative light poles are also perfect for acheiving a historical look commonly found in downtown areas, parks, and homeowner associations. It's often the choice of designers who want an urban look, or for city planners who want durablity and ease of maintenance.",
  },
  {
    text: 'Our decorative poles gives you a highly cost-effective solution with little maintenance as maintaining decorative light poles can be an economical burden.',
  },
  {
    text: 'Our decorative poles have been tried and tested across hundreds of projects across Malaysia and has shown unmatched strength, durability and reliability.',
  },
]);

const DecoPole = () => (
  <Section id="decorative-poles">
    <Container>
      <Fade bottom distance="100px">
        <Content
          title="Decorative Poles"
          paragraphs={items}
          link="/decorative-poles.pdf"
        />
        <ImageContainer>
          <StaticImage
            src="../../../assets/images/products/deco-pole/deco-pole.jpg"
            alt=""
          />
        </ImageContainer>
      </Fade>
      <Fade bottom distance="100px">
        {/* You MUST create individual context in this manner if you want to use the carousel + lightbox */}
        <SimpleReactLightbox>
          <CarouselLightbox images={images} slidesToShow={3} />
        </SimpleReactLightbox>
        <StandardPoleProperties />
        <DownloadBrochureButton link="/decorative-poles.pdf" />
      </Fade>
    </Container>
  </Section>
);

export default DecoPole;
