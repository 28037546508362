import Utils from '../../../utils';

const images = Utils.addKeys([
  {
    src: Utils.importOneImage('products/malaysia-railway-pole/slide-1.png'),
    thumbnail: Utils.importOneImage(
      'products/malaysia-railway-pole/slide-1.png'
    ),
  },
]);

export default images;
